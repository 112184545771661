import { useState } from "react"
import { LoginModal } from "../modal";
import { useRouter } from "next/router";
import Link from "next/link";
import { getCookie } from 'cookies-next';
import _ from 'lodash';
import { LogoSecondary } from "./image";
import { colorGreyInActiveCode, colorRedActiveCode, pageLink } from "../../config/site";
import { VehicleIcon, CompareIcon, CalculatorIcon, ProfileIcon, TyreIcon } from "./icon";
import { BsPinMap } from "react-icons/bs";

const BottomNavigationItem = (props) => {
    const { value, router, t, imageActive = <></>, imageInactive = <></> } = props

    return (
        <div className="bottomNavigation_button_outner">
            <div className={'bottomNavigation_button'}>
                <div className='bottomNavigation_buttonFlex'>
                    <div className={'bottomNavigation_icon'}>
                        {
                            value.navigate == router.pathname ? imageActive : imageInactive
                        }
                    </div>
                    <p className={`fontSize_12 ${value.navigate == router.pathname ? 'fontWeight_Bold bottomNavigation_text_active' : 'fontWeight_medium bottomNavigation_text'}`}>
                        {t(value?.title)}
                    </p>
                </div>
            </div>
        </div>
    )
}

export const BottomNavigation = (props) => {
    const { t } = props
    const [opened, setOpened] = useState(false)
    const router = useRouter()
    let userCookies;
    let isLogin;

    userCookies = getCookie("user") && JSON.parse(getCookie("user"));
    isLogin = !_.isEmpty(userCookies) ? true : false;
    
    return (
        <>
            <div className="bottomNavigationMobile"></div>
            <div className='bottomNavigation containerPadding'>
                <Link href={pageLink.vehicle.homepage}>
                    <div className="bottomNavigation_button_column">
                        <BottomNavigationItem 
                            value={{
                                title: "brands",
                                navigate: pageLink.vehicle.homepage,
                            }} 
                            router={router} t={t} 
                            imageActive={<VehicleIcon color={colorRedActiveCode} />}
                            imageInactive={<VehicleIcon />}
                        />
                    </div>
                </Link>
                <Link href={pageLink.compare.homepage}>
                    <div className="bottomNavigation_button_column">
                        <BottomNavigationItem 
                            value={{
                                title: "compare",
                                navigate: pageLink.compare.homepage,
                            }} 
                            router={router} t={t} 
                            imageActive={<CompareIcon color={colorRedActiveCode} />}
                            imageInactive={<CompareIcon />}
                        />
                    </div>
                </Link>
                {/* <Link href={pageLink.tyre.homepage}>
                    <div className="bottomNavigation_button_column">
                        <BottomNavigationItem 
                            value={{
                                title: "tyre",
                                navigate: pageLink.tyre.homepage,
                            }} 
                            router={router} t={t} 
                            imageActive={<TyreIcon color={colorRedActiveCode} />}
                            imageInactive={<TyreIcon color={colorGreyInActiveCode}  />}
                        />
                    </div>
                </Link> */}
                <Link href={"/"}>
                    <div className="cursorPointer">
                        <LogoSecondary isActive={"/" == router.pathname ? true : false} />
                    </div>
                </Link>
                <Link href={pageLink.calculator.vehicleLoad}>
                    <div className="bottomNavigation_button_column">
                        <BottomNavigationItem 
                            value={{
                                title: "calculator",
                                navigate: pageLink.calculator.type,
                            }} 
                            router={router} t={t} 
                            imageActive={<CalculatorIcon color={colorRedActiveCode} />}
                            imageInactive={<CalculatorIcon />}
                        />
                    </div>
                </Link>
                {/* <Link href={pageLink.map.evStation}>
                    <div className="bottomNavigation_button_column">
                        <BottomNavigationItem 
                            value={{
                                title: "map",
                                navigate: pageLink.map.evStation,
                            }} 
                            router={router} t={t}
                            imageActive={<BsPinMap size={'2.6rem'} color={colorRedActiveCode} />}
                            imageInactive={<BsPinMap size={'2.6rem'} color={colorGreyInActiveCode} />}
                        />
                    </div>
                </Link> */}
                {
                    isLogin == false ?
                    <div onClick={()=>setOpened(true)} className="bottomNavigation_button_column">
                        <BottomNavigationItem 
                            value={{
                                title: "profile",
                                navigate: pageLink.profile.homepage,
                            }} 
                            router={router} t={t} 
                            imageActive={<ProfileIcon color={colorRedActiveCode} />}
                            imageInactive={<ProfileIcon />}
                        />
                    </div>
                    :
                    <Link
                        href={pageLink.profile.homepage}
                    >
                        <div className="bottomNavigation_button_column">
                            <BottomNavigationItem 
                                value={{
                                    title: "profile",
                                    navigate: pageLink.profile.homepage,
                                }} 
                                router={router} t={t} 
                                imageActive={<ProfileIcon color={colorRedActiveCode} />}
                                imageInactive={<ProfileIcon />}
                            />
                        </div>
                    </Link>
                }
            </div>
            <LoginModal 
                opened={opened}
                setOpened={setOpened}
                t={t}
            />
        </>
        
    )
}